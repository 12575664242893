import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import currencies from '/lib/currencies'

import Modal from 'react-bootstrap/lib/Modal'
import ReactSelect from '/components/react-select'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Calendar, DateRangePicker } from '/components/react-date-range'
import { 
  sub,
  format, 
  subMonths
} from 'date-fns'

TIME_RANGES = [
  {
    value: 'all'
    label: 'All transactions'
  },
  {
    value: 'pastMonth'
    label: 'Past month'
  },
  {
    value: 'custom'
    label: 'Custom date range'
  },
  {
    value: 'single'
    label: 'Single day'
  }
]

DATE_RANGE_SELECTED_DEFAULT = 'all'
DATE_SELECTED_DEFAULT = new Date()

PERIODS_DEFAULT = {
  startDate: sub(new Date(), { days: 7 })
  endDate: new Date()
  key: 'selection'
}

formatDate = (date) ->
  format(date, 'MMMM d, yyyy')


export default \
class ReportDownloadModal extends React.Component
  @propTypes:
    closeModal: PropTypes.func.isRequired
    baseUrl: PropTypes.string.isRequired
    name: PropTypes.string.isRequired
    open: PropTypes.bool.isRequired
    extended: PropTypes.bool
    filterDateRange: PropTypes.bool
    filterCurrency: PropTypes.array

  constructor: ->
    super arguments...
    @state =
      dateSelected: DATE_SELECTED_DEFAULT
      periods: PERIODS_DEFAULT
      dateRangeSelected: DATE_RANGE_SELECTED_DEFAULT
      currencySelected: @props.filterCurrency?[0]

  getDateRangeParams: =>
    switch @state.dateRangeSelected
      when 'pastMonth'
        monthAgo = format(subMonths(new Date(), 1), 'yyyy-MM-dd')
        now = format(new Date(), 'yyyy-MM-dd')
        {from: monthAgo, to: now}
      when 'custom'
        from = format(@state.periods.startDate, 'yyyy-MM-dd')
        to = format(@state.periods.endDate, 'yyyy-MM-dd')
        {from, to}
      when 'single'
        date = format(@state.dateSelected, 'yyyy-MM-dd')
        {from: date, to: date}
      else
        {}

  getCurrencyParams: =>
    return {currency: @state.currencySelected} if @state.currencySelected 
    {}

  getExtendedParams: =>
    return {gila: '', extended: true} if @props.extended 
    {}

  getURL: =>
    dateRangeParams = @getDateRangeParams()
    currencyParams = @getCurrencyParams()
    extendedParams = @getExtendedParams()

    sp = new URLSearchParams { ...dateRangeParams, ...currencyParams, ...extendedParams }

    [@props.baseUrl, sp].join '?'

  cancel: (evt) =>
    evt?.preventDefault?()
    @props.closeModal()
    @setState({ 
      dateRangeSelected: DATE_RANGE_SELECTED_DEFAULT,
      currencySelected: @props.filterCurrency?[0]
    })

  currencies: =>
    @props.filterCurrency.map (currency) ->
      value: currency, label: currencies[currency]

  formatedDateRangeText: =>
    "#{formatDate(@state.periods.startDate)} - #{formatDate(@state.periods.endDate)}"

  render: ->
    <Modal
      show={@props.open}
      onHide={@props.closeModal}
      className="ReportDownloadModal"
    >
      <form className="form-inline">
        <Modal.Header>
          <Modal.Title>
            <span className="header-title">
              {@props.name}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="selectors">
            {@props.filterDateRange &&
            <ReactSelect
              className="filter-selector date-selector"
              clearable={false}
              options={TIME_RANGES}
              value={@state.dateRangeSelected}
              onChange={({value: dateRangeSelected}) => @setState {dateRangeSelected}}
              placeholder="Date Range"
            />
            }
            {@props.filterCurrency && 
            <ReactSelect
              className="filter-selector currency-selector"
              clearable={false}
              options={@currencies()}
              value={@state.currencySelected}
              onChange={({value: currencySelected}) => @setState {currencySelected}}
              placeholder="Currency"
            />
            }
          </div>
          {if @state.dateRangeSelected == 'custom'
            <div className="calendar">
              <DateRangePicker
                staticRanges={[]}
                inputRanges={[]}
                showDateDisplay={false}
                showMonthAndYearPickers={false}
                months={2}
                maxDate={new Date()}
                direction="horizontal"
                ranges={[@state.periods]}
                onChange={({selection: periods}) => @setState {periods}}
              />
            </div>
          else if @state.dateRangeSelected == 'single'
            <div className="calendar">
              <Calendar
                staticRanges={[]}
                inputRanges={[]}
                showDateDisplay={false}
                showMonthAndYearPickers={false}
                months={2}
                maxDate={new Date()}
                direction="horizontal"
                date={@state.dateSelected}
                onChange={(dateSelected) => @setState {dateSelected}}
              />
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="footer-container">
            <div className="date-text">
              {if @state.dateRangeSelected == 'custom'
                <span>
                  {@formatedDateRangeText()}
                </span>
              else if @state.dateRangeSelected == 'single'
                <span>
                  {formatDate(@state.dateSelected)}
                </span>
              }
            </div>
            <div className="action-buttons">
              <button className='btn btn-default' onClick={@cancel}>
                Cancel
              </button>
              <a className='btn btn-primary' href={@getURL()} download>
                Download Report
              </a>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
