import React from 'react'

import flash from '/lib/flash'

# components
import Spinner      from '/components/spinner'
import HeroStats    from '/components/hero-stats'
import Note         from '/components/note'
import Empty        from '/components/empty'
import Toggle       from '/components/toggle'
import ReportDownloadModal from '/components/report-download-modal'
import ModalState   from '/components/modal-state'

import FaRefresh from 'react-icons/lib/fa/refresh'
import FaCloudDownload  from 'react-icons/lib/fa/cloud-download'

import {
  ResponsiveContainer
  ComposedChart
  Bar
  XAxis
  YAxis
  Tooltip
} from 'recharts'

import {
  withUser
  withActiveNotes
  withReport
  withDisableAllPresales
  withSyncGilaData
  withUsersRPC
} from './queries'

export default \
withUser \
withActiveNotes \
withReport \
withDisableAllPresales \
withSyncGilaData \
withUsersRPC \
class Summary extends React.Component
  constructor: ->
    super arguments...
    @state =
      inFlight: false
      gila_sync_loading: false

  syncGilaData: =>
    return unless confirm "This will re-sync all tickets and cost items the host has ever sold, which could be significant.\n Are you sure you want to continue?"

    @setState gila_sync_loading: true

    { errors } = await @props.syncGilaData @props.user?.id
    if errors?.length
      flash.error errors
    else
      flash.success "Gila data sync enqueued"
    @setState gila_sync_loading: false

  rpc: (method)=>
    {user, errors} = await @props.users_rpc @props.user.id, method
    if errors?.length
      flash.error errors
    else
      flash.success "User updated successfully"

  render: ->
    return <Spinner/> if @props.userLoading
    return <Empty name="User" /> unless @props.user?.id
    <div>
      <div className="Summary">
        {@notes()}
        {@salesOverview()}
        {@salesByDateChart()}
        {@reportExports()}
        {@actions()}
        {@settings()}
        {@presales()}
      </div>
    </div>

  notes: ->
    return null unless @props.notes?.length
    <div className="notes">
      {<Note key={note._key} note={note}/> for note in @props.notes}
    </div>

  salesOverview: ->
    return <Spinner/> if @props.reportLoading
    return null unless report = @props.report
    <div className="sales-overview">
      <h2>Sales Overview</h2>
      <HeroStats
        data={
          [
            name: 'Orders'
            value: report.tickets_sold
          ,
            name: 'Guests'
            value: report.ticket_items_sold
          ,
            name: 'Total Sales'
            value: report.amountGross
          ,
            name: 'Net Sales'
            value: report.fulfillmentAmount
          ]
        }
      />
    </div>

  salesByDateChart: ->
    return <Spinner/> if @props.reportLoading
    return null unless data = @props.report?.sales_by_date
    <ResponsiveContainer height={250} width={'100%'}>
      <ComposedChart data={data} margin={top: 20, right: 0, left: 0, bottom: 5}>
        <XAxis dataKey="date" hide={true}/>
        <Tooltip/>
        <Bar type="monotone" dataKey="total_amount_net" fill="#28AFFA"/>
      </ComposedChart>
    </ResponsiveContainer>

  reportExports: ->
    return <Spinner/> if @props.userLoading

    reports_by_type =
      csv:
        orders: 'Orders'
        cost_items: 'Tickets'
        add_ons: 'Add-Ons'
        cancellations: 'Cancellations and Refunds'
        listings: 'Ticket Type - Event Level'
        events: 'Ticket Type - Timeslot Level'
        settlement: 'Settlement'
        chargebacks: 'Chargebacks'
        rebates: 'Rebates'
        sales_by_event: 'Sales By Event'
        timeslot_changes: 'Timeslot Changes'
        admin_waitlist: 'Waitlist Subscribers'
        withdrawals: 'Withdrawals'
        activity: 'Activity'
      pdf:
        audit: 'Audit'
        settlement: 'Settlement'

    <div className="report-exports">
      <h2>Report Exports</h2>

      <div className="alert alert-warning" role="alert">
        You must be logged into <a href="universe.com">universe.com</a> as someone with permission to view these reports.
      </div>

      <ul>
        {for ext, reports of reports_by_type
          for key, name of reports
            <li key={"#{key}-report-#{ext}"}>
              <a className="btn btn-default" href={"#{process.env.WEB}/user_reports/#{@props.user.id}/#{key}.#{ext}"}>
                <FaCloudDownload/>
                {name} Report
              </a>
            </li>
        }
        <li key={"audit-extended-report-pdf"}>
          <a className="btn btn-default" href={"#{process.env.WEB}/user_reports/#{@props.user.id}/audit.pdf?extended=true"}>
            <FaCloudDownload/>
            {"Extended Audit Report (PDF)"}
          </a>
        </li>
      </ul>
    </div>


  disable_all_presales: =>
    @setState inFlight: true
    {errors} = await @props.disable_all_presales @props.user.id
    if errors?.length
      flash.error errors
    else
      flash.success "Presales disabled"
    @setState inFlight: false


  presales: ->
    nl = @props.user.num_listings_with_presales
    <div className="presales">
      <h2>
        Presale Listings: {nl}
        <button
          className="btn btn-default"
          disabled={@state.inFlight or !nl}
          onClick={@disable_all_presales}
          style={marginLeft: '1em'}
        >
          {if @state.inFlight
            <span><FaRefresh /> working...</span>
          else
            "Disable all presales"
          }
        </button>

      </h2>
    </div>

  actions: ->
    <div className="actions">
      <h2>Actions</h2>
      <ul>
        <li>
          <a className="btn btn-default" onClick={@syncGilaData} disabled={@state.gila_sync_loading}>
            <FaRefresh />
            Sync Gila Data
          </a>
        </li>
      </ul>
    </div>

  settings: ->
    <div className="settings">
      <h2>Settings</h2>
      <ul>
        <li>
          <Toggle
            value={!!@props.user.ln_all_in_pricing}
            onChange={@rpc.bind @, 'toggle_ln_all_in_pricing!'}
          />
          Live Nation All In Pricing
        </li>
      </ul>
    </div>

  reportDownloadModal: (name, modal) ->
    <ModalState
      render={({open, toggle})=>
        [
          <li key={"#{modal.key}-report-#{modal.ext}"}>
            <a className="btn btn-default #{modal.key}" onClick={toggle}>
              <FaCloudDownload/>
              {name} Report
            </a>
          </li>

          <ReportDownloadModal
            closeModal={toggle}
            open={open}
            name={modal.name}
            baseUrl={"#{process.env.WEB}/user_reports/#{@props.user.id}/#{modal.key}.#{modal.ext}"}
            extended={modal.extended}
            filterDateRange={modal.filterDateRange}
            filterCurrency={if modal.filterCurrency then @props.user.listing_currencies else null}
          />
        ]
    }/>
