import Model from '/lib/model'

export default \
class Attraction extends Model
  @className: 'Attraction'
  constructor: ->
    super arguments...
    @id               = null
    @legacy_id        = null
    @name             = null
    @data             = {}

  surfaceData: ->
    @homepage = @data.homepage
    @facebook_id = @data.facebook?.id
    @itunes_id = @data.itunes?.id
    @spotify_id = @data.spotify?.id
    @youtube_id = @data.youtube?.id

  buryData: ->
    data = {}
    data.homepage = @homepage if @homepage or @data.homepage
    data.legacyId = @legacy_id if @legacy_id or @data.legacyId
    data.facebook = { id: @facebook_id } if @facebook_id or @data.facebook?.id
    data.itunes = { id: @itunes_id } if @itunes_id or @data.itunes?.id
    data.spotify = { id: @spotify_id } if @spotify_id or @data.spotify?.id
    data.youtube = { id: @youtube_id } if @youtube_id or @data.youtube?.id

    @homepage = undefined
    @facebook_id = undefined
    @itunes_id = undefined
    @spotify_id = undefined
    @youtube_id = undefined

    @data = data
