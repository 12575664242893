import React from 'react'

import humanize from 'underscore.string/humanize'
import pluck    from 'underscore-es/pluck'

import Attraction from '/models/attraction'

import ReactSelect  from '/components/react-select'

export default \
class EditAttraction extends React.Component
  constructor: ->
    super arguments...
    if @props.attraction
      attraction = Attraction.parse(@props.attraction)
      attraction.surfaceData()

    @state =
      attraction: attraction or undefined

  saveAttraction: (evt)=>
    evt.preventDefault()
    attraction = @generateAttraction()
    @props.action(attraction)

  generateAttraction: () =>
    @state.attraction.buryData()
    @state.attraction

  updateValue: (key, evt) =>
      # handle text inputs
      value = if evt?.target?.value? then evt?.target?.value else evt
      # handle react select inputs
      value = pluck value, 'value' if Array.isArray value
      # set the state
      attraction = @state.attraction
      attraction[key] = value
      @setState attraction: attraction

  formRow: (name, value, placeholder)=>
    <div className="form-group" key={name}>
      <label className="control-label">{humanize name.replace 'actual_', ''}
        <input
          type="text"
          className="form-control"
          placeholder={placeholder or name}
          value={value or ''}
          onChange={@updateValue.bind @, name}
        />
      </label>
    </div>

  render: ->
    return null unless @state.attraction
    <div>
      <div className="AttractionForm">
        <h2>Fill in Attraction Details</h2>
        <label>
          Discovery ID
          <input type="text" className="form-control" value={@state.attraction?.discovery_id or ''} disabled="disabled" />
        </label>
        <label>
          Name
          <input type="text" className="form-control" value={@state.attraction?.name or ''} disabled="disabled" />
        </label>
        {@formRow 'legacy_id', @state.attraction?.legacy_id, 'Legacy ID'}
        {@formRow 'facebook_id', @state.attraction?.facebook_id, 'Facebook ID'}
        {@formRow 'homepage', @state.attraction?.homepage, 'Homepage URL'}
        {@formRow 'itunes_id', @state.attraction?.itunes_id, 'iTunes ID'}
        {@formRow 'spotify_id', @state.attraction?.spotify_id, 'Spotify ID'}
        {@formRow 'youtube_id', @state.attraction?.youtube_id, 'YouTube ID'}

        <button className="btn btn-primary btn-md" onClick={@saveAttraction}>Save</button>
      </div>
    </div>
